var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "button",
    {
      class: _vm.activeCheckClass + _vm.isFatButton,
      attrs: {
        type: "submit",
        "data-cy": "submit-btn",
        disabled: _vm.isDisabled,
      },
    },
    [
      _vm.loading
        ? _c("div", {}, [
            _c("div", { staticClass: "grid place-items-center" }, [
              _c(
                "svg",
                {
                  staticClass:
                    "motion-reduce:hidden animate-spin h-5 w-5 mr-3 ...",
                  attrs: { viewBox: "0 0 24 24" },
                },
                [
                  _c("path", {
                    attrs: {
                      fill: "#000000",
                      d: "M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z",
                    },
                  }),
                ]
              ),
            ]),
          ])
        : _c(
            "span",
            { staticClass: "inline-flex items-center" },
            [
              _vm._v(_vm._s(_vm.text) + " "),
              _vm.showIcon
                ? _c("icon-chevron", {
                    staticClass:
                      "h-4 ml-3 lg:h-5 inline-block hidden sm:inline-block",
                    attrs: { dir: "right", color: "white" },
                  })
                : _vm._e(),
            ],
            1
          ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
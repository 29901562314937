<template>
  <!-- Font Awesome Pro 5.15.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) -->
  <svg
    width="61"
    height="50"
    viewBox="0 0 61 50"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3 27L20.5688 44L58 3"
      :stroke="color"
      stroke-width="7"
    />
  </svg>
</template>

<script>
export default {
  name: 'IconSucessfull',
  props: {

    color: {
      type: String,
      required: false,
      default: 'black',
    },
  },
}
</script>

<style>
</style>

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", {}, [
    _c("div", { staticClass: "grid place-items-center" }, [
      _c(
        "div",
        { staticClass: "mt-12 items-center justify-center flex" },
        [
          _c("icon-sucessfull", { attrs: { color: "#14C6DF" } }),
          _c("strong", { staticClass: "ml-3 text-3xl" }, [
            _vm._v(
              " " +
                _vm._s(_vm.$t("hello")) +
                ", " +
                _vm._s(_vm.getStore.person.owner) +
                " "
            ),
          ]),
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass: "text-center flex mt-10 mb-2",
          attrs: { "data-cy": "migration_ok-ty-text" },
        },
        [
          _vm._v(
            " " +
              _vm._s(
                _vm.$t("migration.thanks_migration", {
                  plan: this.getDefaultPlanName(this.getChosenPlan.name),
                })
              ) +
              " "
          ),
        ]
      ),
      _vm.getStore.subscription.payment_type === 1
        ? _c(
            "div",
            {
              staticClass:
                "h-16 items-center flex justify-center rounded-sm border-solid border border-gray-200 p-2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "flex justify-center text-base",
                  attrs: { "data-cy": "migration_ok-bankslip_info-text" },
                },
                [
                  !this.date
                    ? _c("span", [
                        _vm._v(
                          " " + _vm._s(_vm.$t("migration.bankslip_email")) + " "
                        ),
                      ])
                    : _c("span", [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.$t("migration.bankslip_available", {
                                date: this.date,
                              })
                            ) +
                            " "
                        ),
                      ]),
                ]
              ),
            ]
          )
        : _vm._e(),
    ]),
    _c(
      "div",
      { staticClass: "mt-6 btn-container" },
      [
        _c("submit-button-default", {
          attrs: {
            text: _vm.$t("understood"),
            "data-cy": "migration-submit-btn",
            loading: _vm.loading,
          },
          nativeOn: {
            click: function ($event) {
              _vm.next()
              _vm.eventGtm("custom.migrationSuccess")
            },
          },
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
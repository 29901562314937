<template>
    <div class="">
        <div class="grid place-items-center">
            <div class="mt-12 items-center justify-center flex">
                <icon-sucessfull :color="'#14C6DF'" />
                <strong class="ml-3 text-3xl">
                    {{ $t("hello") }}, {{ getStore.person.owner }}
                </strong>
            </div>
            <div class="text-center flex mt-10 mb-2" data-cy="migration_ok-ty-text">
                {{
                    $t("migration.thanks_migration", {
                        plan: this.getDefaultPlanName(this.getChosenPlan.name),
                    })
                }}
            </div>
            <div
                v-if="getStore.subscription.payment_type === 1"
                class="h-16 items-center flex justify-center rounded-sm border-solid border border-gray-200 p-2"
            >
                <div
                    class="flex justify-center text-base"
                    data-cy="migration_ok-bankslip_info-text"
                >
                    <span v-if="!this.date">
                        {{ $t("migration.bankslip_email") }}
                    </span>
                    <span v-else>
                        {{
                            $t("migration.bankslip_available", {
                                date: this.date,
                            })
                        }}
                    </span>
                </div>
            </div>
        </div>
        <div class="mt-6 btn-container">
            <submit-button-default
                :text="$t('understood')"
                data-cy="migration-submit-btn"
                :loading="loading"
                @click.native="next(); eventGtm('custom.migrationSuccess')"
            />
        </div>
    </div>
</template>

<script>
import SubmitButtonDefault from "@/components/SubmitButtonDefault.vue";
import { mapGetters, mapActions } from "vuex";
import { HOME_VIEW, MIGRATION_END_VIEW } from "@/router/constants";
import {
    GET_MIGRATION_RESULT,
    MIGRATION_MODULE,
} from "@/store/constants/migration";
import { GET_CHOSEN_PLAN, GET_PLAN } from "@/store/constants/checkout";
import { CHANGE_LOADING } from "@/store/constants/login";
import { STORE, STORE_MODULE } from "@/store/constants/store";
import IconSucessfull from "../components/Icons/IconSuccesfull.vue";
import dayjs from "dayjs";

export default {
    name: MIGRATION_END_VIEW,
    components: {
        SubmitButtonDefault,
        IconSucessfull,
    },
    data() {
        return {
            date: null,
        };
    },

    computed: {
        ...mapGetters({
            getPlan: `${MIGRATION_MODULE}/${GET_PLAN}`,
            getChosenPlan: `${MIGRATION_MODULE}/${GET_CHOSEN_PLAN}`,
            getStore: `${STORE_MODULE}/${STORE}`,
            getNewExpirationDate: `${MIGRATION_MODULE}/${GET_MIGRATION_RESULT}`,
            isDesktop: `isDesktop`,
            eventGtm: 'eventGtm'
        }),
    },

    async created() {
        this.date = dayjs(this.getNewExpirationDate.licenseExpiration).subtract(
            10,
            "day"
        );
        this.date = dayjs(this.date).isBefore()
            ? false
            : dayjs(this.date).format("DD/MM/YYYY");
    },

    methods: {
        ...mapActions({
            changeLoading: CHANGE_LOADING,
        }),

        next() {
            if (this.isDesktop) {
                return alert("@@finalizarfluxoassinatura");
            }
            this.$router.push({
                name: HOME_VIEW,
            });
            location.reload();
        },

        getDefaultPlanName(plan) {
            const planDefault = this.$t("plan_default_name")[plan];
            if (!planDefault) {
                return plan;
            }
            return planDefault;
        },

        errorHandling(error) {
            this.$fire({
                text: error,
                type: "error",
                timer: 6000,
                customClass: "alert-error-1",
            });
            throw new Error(JSON.stringify(error));
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../assets/styles/checkout.scss";

.btn-container {
    width: 50%;
    margin: 30px auto 0 auto;
}
</style>

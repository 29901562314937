<template>
  <button
    type="submit"
    :class="activeCheckClass + isFatButton"
    data-cy="submit-btn"
    :disabled="isDisabled"
  >
    <div v-if="loading" class="">
      <div class="grid place-items-center">
        <svg
          class="motion-reduce:hidden animate-spin h-5 w-5 mr-3 ..."
          viewBox="0 0 24 24"
        >
          <path fill="#000000" d="M12,4V2A10,10 0 0,0 2,12H4A8,8 0 0,1 12,4Z" />
        </svg>
      </div>
    </div>
    <span v-else class="inline-flex items-center"
      >{{ text }}
      <icon-chevron
        dir="right"
        color="white"
        v-if="showIcon"
        class="h-4 ml-3 lg:h-5 inline-block hidden sm:inline-block"
      />
    </span>
  </button>
</template>

<script>
import { IconChevron } from "@/components/Icons";
export default {
  components: {
    IconChevron,
  },
  name: "ButtonDefault",
  data() {
    return {};
  },
  props: ["text", "loading", "disabled", "minHeight", "showIcon", "fatButton"],
  computed: {
    isFatButton() {
      if (this.fatButton) {
        return " fat-button";
      }
      return "";
    },
    isDisabled() {
      return this.loading || this.disabled;
    },
    activeCheckClass() {
      if (this.loading || this.disabled) {
        return "btn-submit-disabled";
      }
      return "btn-submit";
    },
  },

  methods: {},
};
</script>

<style lang="scss" scoped>
.btn-submit {
  @apply text-white font-medium py-3 px-0 rounded text-lg text-center w-full max-w-md;
  background-color: #0099f9;
  &:hover {
    background-color: #0069c9;
    @apply shadow-outline-blue;
  }
  &:active {
    background-color: #0059b9;
    @apply shadow-outline-indigo;
  }
  &.minHeight {
    min-height: 52px;
    height: 52px;
  }
}
.fat-button {
  @apply h-20;
}
@media (max-width: 640px) {
  .btn-submit {
    @apply text-sm;
  }
}
.btn-submit-disabled {
  @apply text-white bg-cool-gray-300 font-medium py-3 px-0 rounded text-lg text-center w-full cursor-not-allowed;
}

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  /* -webkit-text-fill-color: yellow !important; */
}
</style>
